.content-layout {
  padding: 12px 24px;
}

.content-page {
  padding: 24px;
  margin: 0;
  min-height: 480px;
  background-color: white;
  border-radius: 24px;
}