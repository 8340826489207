.page-header {
  background: #fff;
  border-bottom: 1px solid #eee;
  z-index: 2;
}

.main-layout {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 64px auto;
}
