.preview__container {
  width: 75%;
  margin: 0 auto 88px;
  user-select: none;
  pointer-events: none;
}
.preview__iframe-wrapper {
  position: relative;
  padding-top: 177.777778%; /** 9:16 aspect ratio **/
}
.preview__iframe-wrapper:not(:first-of-type) {
  margin-top: 16px;
}

.preview__game-iframe {
  transform: scale(0.5);
  width: 200%;
  height: 200%;
  position: absolute;
  top: -50%;
  left: -50%;
}

.preview__iframe-spinner {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}